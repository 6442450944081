<template>
  <h1>
    {{ title }}
  </h1>
</template>
<script>
import get from "lodash/get";

export default {
  name: "PageTitle",
  props: {
    page: { type: Object, required: true },
    height: { type: String, default: "170px" }
  },
  computed: {
    title() {
      return get(this.page, "title.rendered");
    },
    headerImage() {
      return get(this.page, "featured_image_url");
    }
  }
};
</script>
